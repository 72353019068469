import { Add, Remove } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import Footer from "../components/Footer";
import { mobile } from "../styles/responsive";
import { usePaystackPayment } from "react-paystack";
import { PaystackButton } from 'react-paystack';
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/header";
import { Content2Xl, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { useState, useEffect } from "react";
import { removeEvent, updateCart } from "../redux/cartRedux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import numbro from "numbro";
import moment from "moment";
import axios from "axios";
import { safeTicket } from "../redux/ticketRedux";
import swal from "sweetalert";

const API = process.env.REACT_APP_API_URL;

const TwoColumnContainer = styled.div`
	display: flex;
	flex: 1;
	height: 100vh;
`;

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	text-align: center;
	margin: 20px 0;
`;

const Top = styled.div`
	display: flex;
	align-items: left;
	justify-content: start;
	padding: 20px;
	margin-left: 20px;
	${mobile({ display: "none" })}
`;

const TopButton = styled.button`
	padding: 10px;
	font-weight: 600;
	cursor: pointer;
	border: ${(props) => props.type === "filled" && "none"};
	background-color: ${(props) =>
		props.type === "filled" ? "black" : "transparent"};
	color: ${(props) => props.type === "filled" && "white"};
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 10px;
	align-items: center;
	justify-content: space-between;
	${mobile({
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	})}
`;

const Event = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: space-between;
`;

const EventDetail = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
	${mobile({ flexDirection: "column" })}
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	${mobile({ height: "100%" })}
`;

const Details = styled.div`
	padding: 20px;
	gap: 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-between;
	border-radius: 10px;
	margin-bottom: 20px;
	width: 30vw;
	margin-top: 5px;
	background-color: #ffffff;
	border: 1px solid #c5c4c47a;
	${mobile({ width: "90vw", gap: "20px" })}
`;

const EventName = styled.span`
	display: flex;
	justify-content: space-between;
	flex: 1;
`;

const EventId = styled.span`
	display: flex;
	justify-content: space-between;
`;

const TicketType = styled.span`
	display: flex;
	justify-content: space-between;
`;

const PriceDetail = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const EventAmountContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;

const EventAmount = styled.div`
	font-size: 24px;
	margin: 5px;
	${mobile({ margin: "5px 15px" })}
`;

const EventPrice = styled.div`
	font-size: 30px;
	font-weight: 200;
	${mobile({ marginBottom: "20px" })}
`;

const Summary = styled.div`
	flex: 1;
	border: 0.5px solid lightgray;
	border-radius: 10px;
	padding: 20px;
	align-items: center;
	justify-content: "space-between";
	width: 30vw;
	background-color: #ffffff;
	${mobile({ width: "90vw" })}
`;

const SummaryTitle = styled.h1`
	font-weight: 500;
	font-size: 18px;
	text-align: center;
`;

const SummaryItem = styled.div`
	margin: 30px 0px;
	display: flex;
	justify-content: space-between;
	font-weight: ${(props) => props.type === "total" && "500"};
	font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
	width: 100%;
	padding: 10px;
	background-color: black;
	color: white;
	font-weight: 600;
`;

const Cart = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const cart = useSelector((state) => state.cart);
	const [newQuantity, setNewQuantity] = useState(cart.quantity);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const discount = Number(cart?.event?.selectedCategory?.discount) || 0;
	const discountedTotal = cart.total ? parseFloat(cart.total - (discount * cart.total) / 100) : 0;

	useEffect(() => {
		dispatch(updateCart({ ...cart, quantity: newQuantity }));
	}, [newQuantity, dispatch, cart]);

	useEffect(() => {
		if (!cart.event || !cart.customer_details) {
			swal({
				title: "Your Cart is Empty!",
				text: "Add an event to your cart",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			});
			removeEvent();
			handleEmpty();
			navigate("/");
		}
	}, [cart.customer_details, cart.event, navigate]);

	const config = {
		reference: new Date().getTime().toString(),
		email: cart?.customer_details?.customerEmail ?? "",
		amount: cart?.total ? cart.total * 100 : 0,
		publicKey: process.env.REACT_APP_PUBLIC_KEY,
		metadata: {
            custom_fields: [
                {
                    display_name: cart?.customer_details?.customerName ?? "",
					ticket_category: cart?.event?.ticketCategory?.label ?? "",
                }
			]
	}};

	const handlePaystackCloseAction = () => {
	
		navigate("/failure", { replace: true });
	};

	const componentProps = {
        ...config,
        text: 'Pay Now',
        onSuccess: (reference) => createTicket(reference),
        onClose: handlePaystackCloseAction,
    };
	
	
	// Function to handle delay
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	// Refactored createTicket function with retry logic
	const createTicket = async (reference, attempts = 3) => {
		setIsSubmitting(true);
		for (let i = 0; i < attempts; i++) {
			try {
				const { data } = await axios.post(`${API}/tickets`, {
					eventRef: cart?.event?._id,
					name: cart?.customer_details?.customerName ?? "",
					email: cart?.customer_details?.customerEmail ?? "",
					phone: cart?.customer_details?.customerPhone ?? "",
					ticketCategory: cart?.event?.ticketCategory?.label ?? "",
					numberOfTickets: cart.quantity,
					amountPaid: discountedTotal,
				});
				// Success logic
				dispatch(safeTicket(data.data));
				navigate("/thank-you", { replace: true });
				console.log("Paystack Ref:", reference);
				setIsSubmitting(false);
				return; // Exit the loop if successful
			} catch (error) {
				console.error(`Attempt ${i + 1} failed:`, error);
				if (i < attempts - 1) {
					// Wait before retrying if not the last attempt
					await delay(1000); // Delay 1 second between retries
				} else {
					// Final failure after retries
					console.log("Failed to Create Ticket")
					alert("Failed to Create Ticket");
					navigate("/failure", { replace: true });
				}
			}
		}
		setIsSubmitting(false);
	};


	// const createTicket = (reference) => {
	// 	axios
	// 		.post(`${API}/tickets`, {
	// 			eventRef: cart?.event?._id,
	// 			name: cart?.customer_details?.customerName ?? "",
	// 			email: cart?.customer_details?.customerEmail ?? "",
	// 			phone: cart?.customer_details?.customerPhone ?? "",
	// 			ticketCategory: cart?.event?.ticketCategory?.label ?? "",
	// 			numberOfTickets: cart.quantity,
	// 			amountPaid: discountedTotal,
	// 		})
	// 		.then((data) => {

	// 				console.log("Response:", data);
	// 				dispatch(safeTicket(data.data));
	// 				navigate("/thank-you", { replace: true });
	// 				console.log("Paystack Ref:", reference);
	// 		}).catch((error) => {
	// 			console.log(error);
	// 			navigate("/failure", { replace: true });
	// 		})
	// };

	// Move createTicket inside handleCheckout to ensure it runs after payment success
	


    // const handleCheckout = () => {
    //     // The createTicket function should run only after successful payment
    //     // const createTicket = () => {
    //     //     axios
    //     //         .post(`${API}/tickets`, {
    //     //             eventRef: cart?.event?._id,
    //     //             name: cart?.customer_details?.customerName ?? "",
    //     //             email: cart?.customer_details?.customerEmail ?? "",
    //     //             phone: cart?.customer_details?.customerPhone ?? "",
    //     //             ticketCategory: cart?.event?.ticketCategory?.label ?? "",
    //     //             numberOfTickets: cart.quantity,
    //     //             amountPaid: discountedTotal,
    //     //         })
    //     //         .then((data) => {
    //     //             if (data) {
    //     //               
    //     //                 dispatch(safeTicket(data.data));
    //     //                 navigate("/thank-you", { replace: true });
    //     //             } else {
    //     //                 navigate("/failure", { replace: true });
    //     //             }
    //     //         })
    //     //         .catch((error) => {
    //     //             console.log(error);
    //     //             navigate("/failure", { replace: true });
    //     //         })
    //     //         .finally(() => {
    //     //             navigate("/", { replace: true });
    //     //         });
    //     // };

    //     // const onSuccess = (reference) => {
    //     //     console.log("Payment successful. Reference: ", reference);
    //     //     createTicket(); // Call createTicket after successful payment
    //     // };

    //     // const onClose = () => {
    //     //   
    //     //     navigate("/failure", { replace: true });
    //     // };

    //     // Ensure payment initialization occurs, and handle success and failure appropriately
    //     // initializePayment(onSuccess, onClose);
    // };

	const handleQuantity = (type) => {
		if (type === "inc") {
			setNewQuantity(newQuantity + 1);
		} else if (type === "dec") {
			if (newQuantity > 0) {
				setNewQuantity(newQuantity - 1);
			}
		}
	};

	const handleEmpty = () => {
		dispatch(removeEvent());
		navigate("/");
	};

	return (
		<AnimationRevealPage>
			<Header />
			<ContentWithPaddingXl>
				<Content2Xl>
					<>
						<Title>YOUR CART</Title>
					</>
					<Top>
						<Link to="/">
							<TopButton>CONTINUE SHOPPING</TopButton>
						</Link>
					</Top>
					<EventDetail>
						<TwoColumnContainer>
							<Image src={cart?.event?.eventBanner?.url ?? ""} />
						</TwoColumnContainer>
						<Info>
							<Details>
								<SummaryTitle>ORDER DETAILS</SummaryTitle>
								<Event>
									<TicketType>
										<div>Ticket Category:</div> <div>{cart?.event?.ticketCategory?.label ?? "N/A"}</div>
									</TicketType>
									<EventId>
										<span>Event Venue :</span> <span>{cart?.event?.address ? `${cart.event.address} ${cart.event.state}` : "N/A"}</span>
									</EventId>
									<EventName>
										<div>Event Name:</div> <div>{cart?.event?.title ?? "N/A"}</div>
									</EventName>
									<EventName>
										<div>Event Date:</div> <div>{cart?.event?.eventDate ? moment(cart.event.eventDate).format("MMMM Do YYYY") : "N/A"}</div>
									</EventName>
									<EventName>
										<div>Name:</div> <div>{cart?.customer_details?.customerName ?? "N/A"}</div>
									</EventName>
									<EventName>
										<div>Email:</div> <div>{cart?.customer_details?.customerEmail ?? "N/A"}</div>
									</EventName>
									<EventName>
										<div>Phone number:</div> <div>{cart?.customer_details?.customerPhone ?? "N/A"}</div>
									</EventName>
								</Event>
								<PriceDetail>
									<EventAmountContainer>
										<Add onClick={() => handleQuantity("inc")} />
										<EventAmount>Quantity: {cart?.quantity}</EventAmount>
										<Remove onClick={() => handleQuantity("dec")} />
									</EventAmountContainer>
									<EventPrice>
										₦{" "}
										{numbro(cart?.total ?? 0).format({
											thousandSeparated: true,
											mantissa: 2,
										})}
									</EventPrice>
									<Button onClick={handleEmpty}>Cancel Purchase</Button>
								</PriceDetail>
							</Details>

							<Summary>
								<SummaryTitle>ORDER SUMMARY</SummaryTitle>
								<SummaryItem>
									<SummaryItemText>Ticket Price</SummaryItemText>
									<SummaryItemPrice>
										₦{" "}
										{numbro(cart?.event?.priceValue ?? 0).format({
											thousandSeparated: true,
											mantissa: 2,
										})}
									</SummaryItemPrice>
								</SummaryItem>
								<SummaryItem>
									<SummaryItemText>Ticket Quantity</SummaryItemText>
									<SummaryItemPrice>{cart.quantity}</SummaryItemPrice>
								</SummaryItem>
								<SummaryItem>
									<SummaryItemText>Subtotal</SummaryItemText>
									<SummaryItemPrice>
										₦{" "}
										{numbro(cart?.total ?? 0).format({
											thousandSeparated: true,
											mantissa: 2,
										})}
									</SummaryItemPrice>
								</SummaryItem>
								<SummaryItem>
									<SummaryItemText>Discount(%)</SummaryItemText>
									<SummaryItemPrice>{discount}%</SummaryItemPrice>
								</SummaryItem>
								<SummaryItem type="total">
									<SummaryItemText>Total</SummaryItemText>
									<SummaryItemPrice>
										₦{" "}
										{numbro(discountedTotal).format({
											thousandSeparated: true,
											mantissa: 2,
										})}
									</SummaryItemPrice>
								</SummaryItem>

								{/* <Button onClick={handleCheckout}>PAY NOW</Button> */}
								<Button>

								<PaystackButton {...componentProps} />
								</Button>
							</Summary>
						</Info>
					</EventDetail>
				</Content2Xl>
			</ContentWithPaddingXl>
			<Footer />
		</AnimationRevealPage>
	);
};

export default Cart;
